// @flow

import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import ViciAbcContent from '../components/ProjectsContent/ViciAbcContent/ViciAbcContent';

const ViciAbcPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      A few examples of upcoming VIČI project Surimi ABC.
      Surreal photo manipulations with characters of various fish and surimi sticks.
      <br/><br/>
      Made with a team in ALL CAPS agency.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <ViciAbcContent/>
    </ProjectLayout>
  );
};

export default ViciAbcPage;
